import React, { FC } from 'react';
import Button, { ButtonProps } from './Button';
import useTranslation from 'hooks/useTranslation';

export interface CancelButtonProps extends ButtonProps {
  label?: string;
}

const CancelButton: FC<CancelButtonProps> = ({
  label,
  variant = 'secondary',
  children,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Button variant={variant} {...rest}>
      {label || children || t('Cancel')}
    </Button>
  );
};

export default CancelButton;
