import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    surface: {
      primary: string;
      secondary: string;
      disabled: string;
    };
  }
  interface PaletteOptions {
    surface: {
      primary: string;
      secondary: string;
      disabled: string;
    };
  }
}

const COLORS = {
  blau100: '#e0e6ff',
  blau500: '#392f9f',
  green50: '#ecfdf3',
  green100: '#8de6cb',
  green500: '#12b76a',
  neutral0: '#ffffff',
  neutral25: '#fafafa',
  neutral50: '#e9e8ed',
  neutral100: '#d2d1db',
  neutral200: '#a5a4b7',
  neutral300: '#797693',
  neutral400: '#4c496f',
  neutral500: '#1f1b4b',
  orange50: '#fff6ed',
  orange500: '#ffa347',
  red50: '#fef3f2',
  red500: '#f87171',
  red700: '#f04438',
  rosa100: '#ffe3e6',
  rosa500: '#ff5d79',
  teal500: '#00cccd',
  violett50: '#f5f4fe',
  violett100: '#edebfc',
  violett500: '#7c4de1',
};

const SPACING = {
  spacing0: 0,
  spacing1: 4,
  spacing2: 8,
  spacing3: 12,
  spacing4: 16,
  spacing5: 20,
  spacing6: 24,
  spacing7: 32,
};

const TOKENS = {
  borderPrimary: COLORS.blau100,
  brandBlau: COLORS.blau500,
  brandBlauLight: COLORS.blau100,
  brandRosa: COLORS.rosa500,
  brandRosaLight: COLORS.rosa100,
  brandViolett: COLORS.violett500,
  brandViolettLight: COLORS.violett100,
  radiusMd: SPACING.spacing3,
  radiusSm: SPACING.spacing2,
  radiusXs: SPACING.spacing1,
  secondaryTeal: COLORS.teal500,
  spacingL: SPACING.spacing5,
  spacingM: SPACING.spacing4,
  spacingNone: SPACING.spacing0,
  spacingS: SPACING.spacing3,
  spacingXl: SPACING.spacing6,
  spacingXs: SPACING.spacing2,
  spacingXxl: SPACING.spacing7,
  spacingXxs: SPACING.spacing1,
  statusErrorDark: COLORS.red700,
  statusErrorLight: COLORS.red50,
  statusErrorPrimary: COLORS.red500,
  statusSuccessDark: COLORS.green500,
  statusSuccessLight: COLORS.green50,
  statusSuccessPrimary: COLORS.green100,
  statusWarningLight: COLORS.orange50,
  statusWarningPrimary: COLORS.orange500,
  surfaceBackground: COLORS.neutral50,
  surfaceDisabled: COLORS.neutral50,
  surfaceHover: COLORS.neutral50,
  surfacePrimary: COLORS.neutral0,
  surfaceSecondary: COLORS.neutral25,
  textDisabled: COLORS.neutral200,
  textInvert: COLORS.neutral0,
  textPrimary: COLORS.neutral500,
  textSecondary: COLORS.neutral400,
};

const theme = createTheme({
  typography: palette => ({
    fontFamily: 'Plus Jakarta Sans',
    h1: {
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '48px',
    },
    h2: {
      fontWeight: 600,
      fontSize: '32px',
      lineHeight: '40px',
    },
    h3: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '32px',
    },
    h4: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
    },
    button: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      textTransform: 'none',
    },
    caption: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
    },
    overline: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      textTransform: 'none',
    },
  }),
  palette: {
    primary: {
      main: TOKENS.brandViolett,
      light: TOKENS.brandViolettLight,
    },
    secondary: {
      main: TOKENS.textSecondary,
    },
    text: {
      primary: TOKENS.textPrimary,
      secondary: TOKENS.textSecondary,
      disabled: TOKENS.textDisabled,
    },
    error: {
      main: TOKENS.statusErrorPrimary,
      dark: TOKENS.statusErrorDark,
      light: TOKENS.statusErrorLight,
    },
    divider: TOKENS.borderPrimary,
    surface: {
      primary: TOKENS.surfacePrimary,
      secondary: TOKENS.surfaceSecondary,
      disabled: TOKENS.surfaceDisabled,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          top: 'calc(50% - 12px)',
        },
        listbox: {
          padding: 0,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        //size: 'small',
        autoComplete: 'off',
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: TOKENS.radiusMd,
          '& fieldset': {
            borderColor: theme.palette.divider,
          },
          '&:hover fieldset': {
            borderColor: `${theme.palette.primary.main}!important`,
          },
          '&.Mui-focused fieldset': {
            borderColor: `${theme.palette.primary.main}!important`,
          },
        }),
        input: {
          minHeight: '23px!important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: 40,
          borderRadius: TOKENS.radiusMd,
        },
      },
    },
    MuiList: {
      defaultProps: {
        disablePadding: true,
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          height: 40,
          borderRadius: TOKENS.radiusMd,
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: TOKENS.radiusXs,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        expandIconWrapper: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: TOKENS.radiusMd,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: `${theme.palette.text.primary}50`,
        }),
        invisible: {
          background: 'transparent',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: ({ theme }) => ({
          backgroundColor: `${theme.palette.surface.secondary}!important`,
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        //color: 'secondary',
        //viewBox: '0 0 20 20',
        //fontSize: 'small',
      },
      styleOverrides: {
        colorAction: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiPaper: {
      defaultProps: {
        square: true,
        elevation: 0,
      },
      styleOverrides: {
        rounded: {
          borderRadius: TOKENS.radiusMd,
        },
        outlined: {
          boxShadow: `
              3px 0px 6px 0px #D1D1D11A,
              11px 0px 11px 0px #D1D1D117,
              25px 0px 15px 0px #D1D1D10D,
              44px 0px 18px 0px #D1D1D103,
              68px 0px 19px 0px #D1D1D100
          `,
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiPaper-root': {
            borderRadius: TOKENS.radiusMd,
            border: `1px solid ${theme.palette.divider}`,
            boxShadow: `
              0px 8px 8px -4px #10182808,
              0px 20px 24px -4px #10182814
            `,
          },
        }),
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiPaper-root': {
            borderRadius: TOKENS.radiusMd,
            border: `1px solid ${theme.palette.divider}`,
            boxShadow: `
              0px 8px 8px -4px #10182808,
              0px 20px 24px -4px #10182814
            `,
          },
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiBadge: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          backgroundColor: theme.palette.error.main,
        }),
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      tablet: 640,
      laptop: 1024,
      desktop: 1280,
    },
  },
});

export default theme;
