import React, { FC, useCallback } from 'react';
import {
  Chip,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from '@mui/material';
import DialogTitle from './components/DialogTitle';
import useTranslation from 'hooks/useTranslation';
import CancelButton, {
  CancelButtonProps as CancelButtonPropsType,
} from 'components/Button/CancelButton';
import ConfirmButton, {
  ConfirmButtonProps as ConfirmButtonPropsType,
} from 'components/Button/ConfirmButton';
import { combineSX } from 'helpers';

export type AlertDialogVariant = 'success' | 'warning' | 'error' | 'notice';

const DIALOGS = {
  success: {
    title: 'success',
    color: '#12B76A',
  },
  warning: {
    title: 'warning',
    color: '#FFA347',
  },
  error: {
    title: 'error',
    color: '#FF5D79',
  },
  notice: {
    title: 'notice',
    color: '#392F9F',
  },
};

interface DialogProps extends Omit<MuiDialogProps, 'open'> {
  open?: boolean;
  variant: AlertDialogVariant;
  title?: string;
  onClose: () => void;
  onConfirm: () => void;
  CancelButtonProps?: CancelButtonPropsType;
  ConfirmButtonProps?: ConfirmButtonPropsType;
}

const AlertDialog: FC<DialogProps> = ({
  children,
  onClose,
  onConfirm,
  open = true,
  variant,
  title,
  maxWidth,
  fullWidth,
  CancelButtonProps,
  ConfirmButtonProps,
  ...rest
}) => {
  const { t } = useTranslation();

  const isCloseDisabled = ConfirmButtonProps?.loading;

  const handleClose = useCallback(() => {
    if (!isCloseDisabled) onClose();
  }, [onClose, isCloseDisabled]);

  return (
    <MuiDialog
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClose={handleClose}
      {...rest}
      PaperProps={{
        ...rest.PaperProps,
        sx: combineSX(
          {
            p: 2,
            minWidth: 330,
          },
          rest.PaperProps?.sx,
        ),
      }}
    >
      <DialogTitle
        onClose={handleClose}
        isCloseDisabled={isCloseDisabled}
        sx={{
          p: 2,
          pt: 0,
          borderBottom: 'unset',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Chip
          label={title || t(DIALOGS[variant].title)}
          variant="outlined"
          sx={{
            height: 28,
            display: 'inline-flex',
            padding: '4px 6px',
            backgroundColor: `${DIALOGS[variant]?.color}19`,
            color: DIALOGS[variant]?.color,
            borderColor: DIALOGS[variant]?.color,
            borderRadius: '4px',
            overflow: 'hidden',
            '& .MuiChip-label': {
              display: 'inline-block',
              maxWidth: '100%',
            },
          }}
        />
      </DialogTitle>
      <MuiDialogContent
        sx={{
          p: 2,
          paddingTop: '16px!important',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </MuiDialogContent>
      <MuiDialogActions
        sx={theme => ({
          alignItems: 'center',
          justifyContent: 'center',
          borderTop: `1px solid ${theme.palette.divider}`,
          p: 2,
          pb: 0,
        })}
      >
        <CancelButton
          onClick={onClose}
          variant="tertiary"
          {...CancelButtonProps}
          sx={combineSX(
            {
              minWidth: 80,
            },
            CancelButtonProps?.sx,
          )}
        >
          {t('Cancel')}
        </CancelButton>
        <ConfirmButton
          onClick={onConfirm}
          {...ConfirmButtonProps}
          sx={combineSX(
            {
              minWidth: 93,
            },
            ConfirmButtonProps?.sx,
          )}
        >
          {t('Yes')}
        </ConfirmButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};

export default AlertDialog;
