import React, { FC } from 'react';
import {
  DialogContent as MuiDialogContent,
  DialogContentProps as MuiDialogContentProps,
  Theme,
} from '@mui/material';
import { combineSX } from 'helpers';

export interface DialogContentProps extends MuiDialogContentProps {}

const DialogContent: FC<DialogContentProps> = ({ children, sx, ...rest }) => (
  <MuiDialogContent
    {...rest}
    sx={combineSX(
      {
        padding: (theme: Theme) =>
          `${theme.spacing(3)} ${theme.spacing(2)}!important`,
      },
      sx,
    )}
  >
    {children}
  </MuiDialogContent>
);

export default DialogContent;
